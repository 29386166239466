import React from 'react';

import './App.css'
import styled from 'styled-components'
import Prism from "prismjs";
import PrismPython from "prismjs/components/prism-python.min";
import PrismLineNumbers from "prismjs/plugins/line-numbers/prism-line-numbers.min"
import PrismToolbar from "prismjs/plugins/toolbar/prism-toolbar.min"
import PrismCopyToClipboard from "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min"

import A2D24Logo from './a2d24.svg'
import GithubLogo from './github_logo.svg'

import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/toolbar/prism-toolbar.css';

import {
    attributes_demo,
    custom_model_demo, extra_features_demo,
    filters_demo,
    gsi_demo,
    keys_demo,
    pagination_demo,
    simple_query_boto,
    simple_query_botoful,
    sort_key_demo,
    template_demo
} from './snippets'

function App() {

    React.useEffect(() => {
        Prism.highlightAll();

    }, [Prism, PrismPython, PrismLineNumbers, PrismToolbar, PrismCopyToClipboard])

    return (
        <ContainerMain>
            <Main>
                <Header>
                    <Logo>boto<Emphasis>ful</Emphasis></Logo>
                    <Right>
                        <a href="https://github.com/a2d24/botoful"><img src={GithubLogo} alt="Github Link" width={50}/></a>
                    </Right>
                </Header>
                <Hero>
                    <HeroText>dynamodb queries <Emphasis>simplified</Emphasis></HeroText>
                    <SideBySide>
                        <Code>
                            <TextCentered>boto3</TextCentered>

                            <Pre>
                                <code className="language-py">
                                    {simple_query_boto}
                                </code>
                            </Pre>

                        </Code>

                        <Code>
                            <TextCentered>boto<Emphasis>ful</Emphasis></TextCentered>
                            <Pre>
                                <code className="language-py">
                                    {simple_query_botoful}
                                </code>
                            </Pre>
                        </Code>
                    </SideBySide>
                    <Installation>
                        <Code>
                            <SectionTitle>Getting Started</SectionTitle>
                            <Pre>
                                > <code className="language-py">pip install botoful[boto]</code>
                            </Pre>
                        </Code>
                    </Installation>
                </Hero>
            </Main>
            <Panel>
                <SectionTitle>Composable Queries</SectionTitle>
                <SectionText>
                    Build and reuse your queries intuitively
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-py">
                            {keys_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Panel>
                <SectionTitle>Pagination</SectionTitle>
                <SectionText>
                    By default, all matches are returned - you can opt into pagination when required
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-python">
                            {pagination_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Panel>
                <SectionTitle>Dynamic Queries</SectionTitle>
                <SectionText>
                    Define dynamic queries and inject variables during query execution
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-python">
                            {template_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Panel>
                <SectionTitle>Easy Sort Key Conditions</SectionTitle>
                <SectionText>
                    Perform efficient queries using sort key conditions
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-python">
                            {sort_key_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Panel>
                <SectionTitle>Get Specific Attributes</SectionTitle>
                <SectionText>
                    Specify which attributes you require - reserved dynamodb keywords are handled automatically
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-python">
                            {attributes_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Panel>
                <SectionTitle>Global Secondary Indexes</SectionTitle>
                <SectionText>
                    Easily query your tables indexes
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-python">
                            {gsi_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Panel>
                <SectionTitle>Use Custom Models</SectionTitle>
                <SectionText>
                    Your model will receive each parameter from the result as a keyword argument
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-python">
                            {custom_model_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Panel>
                <SectionTitle>Filter Expressions</SectionTitle>
                <SectionText>
                    Use operators on attributes to filter results
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-python">
                            {filters_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Panel>
                <SectionTitle>Control your Query</SectionTitle>
                <SectionText>
                    Opt into consistent reads and specify the scan direction
                </SectionText>

                <HalfCodeBlock>
                    <pre>
                        <code className="language-python">
                            {extra_features_demo}
                        </code>
                    </pre>
                </HalfCodeBlock>
            </Panel>
            <Spacer/>
            <Footer>
                <p>powered by</p>
                <a href={"https://www.a2d24.com"} target="_blank"><img src={A2D24Logo} alt="A2D24 Logo"
                                                                       width={200}/></a>
                <br/>
                Copyright &copy; All Rights Reserved
            </Footer>
        </ContainerMain>
    );
}

const TextCentered = styled.p`
    text-align: center;
    font-size: 1.2em;
`
const Container = styled.div`
    background: #E7ECEF;
    width: 100%;
`

const ContainerMain = styled(Container)`
    min-height: 100vh;
`
const Logo = styled.p`
    color: #48639c;
    font-size: 3em;
    justify-self: flex-start;
    margin: 0;
    padding: 0.4em;
`
const Emphasis = styled.span`
    color: #D66853;
`

const HeroText = styled.p`
    color: #0C120C;
    font-size: 3em;
    font-weight: 300;
    
      @media (max-width: 768px) {
    font-size: 1.5em;
      }
`

const SectionTitle = styled.p`
    color: #D66853;
    text-align: center;
    font-size: 1.5em;
    padding-bottom: 0.5em;
    margin-top: 2em;
`

const SectionText = styled.p`
    text-align: center;
    font-size: 1.4em;
    font-weight: 300;
`
const Installation = styled.div`
    align-self: flex-start;
    padding-top: 2em;
`

const Panel = styled.div`
  width: 100%;
  
  @media (min-width: 1201px) {
    width: 80%;
  }
  margin: 0 auto;
  display: grid;
`

const Main = styled(Panel)`
  min-height: 100vh;
  grid-template-rows: 75px auto;
`

const Header = styled.header`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr;
    width: 100%
    height: 100%;
    place-items: center center;
`

const Right = styled.div`
    justify-self: flex-end;
    padding: 0.4em;
`
const HalfCodeBlock = styled.div`
    width: 100%;
    padding: 1em;
    @media (min-width: 1201px) {
        width: 60%;
    }
    
    justify-self: center;
`

const DebugDiv = styled.div`
    border: 1px solid red;
`

const Hero = styled.div`
    display: grid;
    place-items: center center;
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: 1fr;
    grid-gap: 1em;
`

const Code = styled.div`
    width: 100%;
`
const SideBySide = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr;
    padding: 1em;
   @media (min-width: 1201px) {
        grid-template-columns: 1fr 1fr;
    }
`

const Pre = styled.pre`
    width: 100%;
`

const Spacer = styled.div`
    padding: 3em;
`

const Footer = styled.div`
    border-top: 1px solid #ccc;
    padding: 2em;
    text-align: center;
`
export default App;
