export const simple_query_botoful = `import boto3
from botoful import Query

client = boto3.client('dynamodb')

result = Query(table='Cars') \\
            .key(brand='BMW') \\
            .attributes(['brand', 'year', 'model']) \\
            .execute(client)

print(result.items)

`
export const simple_query_boto = `import boto3
from boto3.dynamodb.conditions import Key

dynamodb = boto3.resource('dynamodb')

response = dynamodb.Table('Cars').query(
    KeyConditionExpression=Key('brand').eq("BMW"),
    ProjectionExpression='brand,#year,model',
    ExpressionAttributeNames={'#year': 'year'}
)

print(response['Items'])
`
export const simple_boto_query_response = `{
  "year": Decimal('2020'),
  "brand": "BMW",
  "model": "1 Series"
}
...`

export const simple_query_boto_2 = `import boto3

client = boto3.client('dynamodb')

response = client.query(
    TableName='Cars',
    KeyConditionExpression='brand = :brand',
    ExpressionAttributeValues={':brand': {'S': 'BMW'}},
    ProjectionExpression='brand,#year,model',
    ExpressionAttributeNames={'#year': 'year'}
)

for car in response['Items']:
    print(car)

# {
#   "year": {
#     "N": "2020"
#   },
#   "brand": {
#     "S": "BMW"
#   },
#   "model": {
#     "S": "1 Series"
#   }
# }
# ...`


export const keys_demo = `import boto3
from botoful import Query

client = boto3.client('dynamodb')

# Query by partition key and sort key directly
cars = Query(table='Cars').key(brand='BMW', model='1 Series').execute(client)

# Composable queries
table = Query(table='Cars')

vw_cars_query = table.key(brand='VW')

bmw_cars_query = table.key(brand='BMW')
bmw_1_series_query = bmw_cars_query.key(model='1 Series')

# ...
# result = your_query.execute(client)
# print(result.items)
# print(result.count)
`

export const pagination_demo = `query = Query(table='Cars').key(brand='BMW').page_size(10)

first_page = query.execute(client)

# first_page.next_token is None if there are no more pages
if first_page.next_token is not None:

    # the token is base64 encoded and can be passed to a client for UI pagination
    starting_token = first_page.next_token
    second_page = query.execute(client, starting_token=starting_token)`

export const template_demo = `query = Query(table='Cars').key(brand='{brand_name}')

params = {"brand_name": "BMW"}
results = query.execute(client, params=params)`

export const sort_key_demo = `# Fetch all BMW models which begins with the character '1'
query = Query(table='Cars').key(brand='BMW', model__begins_with='1')
result = query.execute(client)

# Also supports __gt, __gte, __lt, __lte, __between=[start,end]
`

export const attributes_demo = `query = Query(table='Cars').key(brand='BMW').attributes(['model', 'year'])
result = query.execute(client)

print(result.items)

# [
#   {
#       'year': Decimal('2020'), 
#       'model': '1 Series'
#   }, 
#   {
#       'year': Decimal('2020'), 
#       'model': '2 Series Coupé'
#   }
#   ...
# ]`

export const gsi_demo = `# Wrap your query in brackets if you prefer a cleaner multiline expression
query = (
    Query(table='Cars')
        .index('brand-year-index')
        .key(brand='BMW', year__between=[2000, 2020])
)
result = query.execute(client)`

export const custom_model_demo = `class Car:
    def __init__(self, brand, model, year):
        self.brand = brand
        self.model = model
        self.year = year

query = (
    Query(table='Cars')
        .key(brand='BMW')
        .attributes(['brand', 'model', 'year'])
)
result = query.execute(client, model=Car)

for car in result.items:
    print(f"{car.brand} | {car.year} - {car.model}")

# BMW | 2020 - 1 Series
# BMW | 2020 - 2 Series Coupé
# BMW | 2020 - 3 Series Sedan
# BMW | 2020 - X5
# ...`

export const filters_demo = `from botoful import ValueOf

result = (
    Query(table='Cars')
        .key(brand='BMW')
        .filter(ValueOf('year').between(2019, 2021) & ValueOf('type').eq('Sedan'))
        .execute(client=client)
)

# ValueOf is an alias of boto3's Attr
# Supports: eq, ne,  lt, lte, gt, gte, between
#           begins_with, is_in, exists, not_exists, contains`

export const extra_features_demo = `result = (
    Query(table='Cars')
        .key(brand='BMW')
        .backwards()
        .consistent()
        .execute(client=client)
)

# Default behavior is query.forwards() and query.consistent(False)`